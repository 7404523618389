import { NavigationItem } from '../models/interfaces/navigation';
import { SoctripIcons } from './soctrip-icon.enum';

export const MenuSidebar: NavigationItem[] = [
  {
    path: 'voucher-management',
    title: 'voucher-management',
    icon: SoctripIcons.TICKET_01,
  },
  {
    path: 'voucher-v2',
    title: 'voucher-v2',
    icon: SoctripIcons.TICKET_01,
    environmentsExcept: ['PRODUCTION'],
  },
];
