<div
  [ngClass]="isMenuShow ? 'w-[16.5rem]' : 'w-[4.25rem]'"
  class="max-2xl:hidden min-h-[calc(100vh-3.750rem)] grid gap-2 content-start px-2 py-3 border-r bg-white transition-all sticky top-[50px]"
>
  <ng-template [ngTemplateOutlet]="navigateSection"></ng-template>
  <i
    (click)="isMenuShow = !isMenuShow"
    class="{{ getMenuIcon() }}
      absolute z-10 top-4 right-0 translate-x-1/2 translate-y-full p-2 text-gray-400 bg-white rounded-full shadow cursor-pointer"
    style="font-size: 0.75rem"
  ></i>
</div>

<p-sidebar (visibleChange)="updateSideBar($event)" [(visible)]="sidebarVisible">
  <ng-template [ngTemplateOutlet]="navigateSection"></ng-template>
</p-sidebar>

<ng-template #navigateSection>
  <!-- PARENT -->
  <div *ngFor="let item of MenuSidebar; let index = index">
    <ng-container *ngIf="!item?.environmentsExcept?.includes(env)">
      <div
        (click)="onClickItemSidebar(item, index)"
        class="relative flex items-center justify-between rounded-sm py-2 pl-4 pr-3 min-w-max select-none transition font-medium cursor-pointer hover:bg-gray-100"
        [ngClass]="[
          item.path && isActive(item.path, !!item.children)
            ? 'text-primary-700 font-semibold bg-primary-100 after:absolute after:left-0 after:h-5 after:w-1 after:rounded-r after:bg-primary-600'
            : ''
        ]"
        [title]="'section-sidebar.' + item.title | translate | sentenceCase"
      >
        <div class="flex items-center gap-3">
          <i
            [ngClass]="
              item.path && isActive(item.path, !!item.children)
                ? 'before:!text-primary-700 font-semibold'
                : ''
            "
            class="{{ item.icon }} text-xl"
          ></i>
          <span *ngIf="isMenuShow || sidebarVisible" class="text-sm">
            {{ "section-sidebar." + item.title | translate | sentenceCase }}
          </span>
        </div>
        <span
          *ngIf="!!item.children && isMenuShow"
          [ngClass]="showChildren[index] ? 'rotate-180' : ''"
          class="pi !text-gray-500 !text-sm pi-chevron-up transition"
        ></span>
      </div>
      <!-- CHILDREN -->
      <ul
        *ngIf="
          item.children && showChildren[index] && (isMenuShow || sidebarVisible)
        "
      >
        <ng-container *ngFor="let child of item.children">
          <li
            *ngIf="!!child.path"
            [ngClass]="[
              isActive(
                item.path ? [item.path, child.path].join('/') : child.path
              )
                ? 'text-primary-700 font-semibold bg-primary-100 hover:bg-gray-100'
                : ''
            ]"
            (click)="onNavigate([item.path, child.path].join('/'))"
            class="mt-1 h-9 flex items-center rounded transition cursor-pointer"
            [title]="
              'section-sidebar.' + child.title | translate | sentenceCase
            "
          >
            <div class="flex items-center h-full">
              <div class="w-11 flex items-center justify-center">
                <span
                  [ngClass]="
                    isActive(
                      item.path ? [item.path, child.path].join('/') : child.path
                    )
                      ? 'bg-primary-600'
                      : 'bg-gray-300'
                  "
                  class="h-1 w-1 rounded-full"
                ></span>
              </div>
              <span class="text-sm">
                {{
                  "section-sidebar." + child.title | translate | sentenceCase
                }}
              </span>
            </div>
          </li>
        </ng-container>
      </ul>
    </ng-container>
  </div>
</ng-template>
